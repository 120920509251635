
$(document).on 'mouseenter', '[data-hint]', (e) ->
  $('#hint-text').html($(this).attr('data-hint'))
  # $('#hint-frame').show()
  $('#hint-frame').stop(true, true).fadeIn(150)
  
$(document).on 'mouseleave', '[data-hint]', (e) ->
  # $('#hint-frame').hide()
  $('#hint-frame').stop(true, true).fadeOut(150)  
  $('#hint-text').html($(this).attr('data-hint'))
  