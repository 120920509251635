$(document).on 'click tap', '[data-action~=filter-category]', () ->
  $.ajax({            
    url: $(this).attr('href') + '.js'
  })
  false

$(document).on 'mouseenter', '.blush-tile, .blush-tile-horizontal', (event) ->  
  $('.blush-tile, .blush-tile-horizontal').removeClass('active')  
  $(this).addClass('active-tile')
  
$(document).on 'mouseleave', '.blush-tile, .blush-tile-horizontal', (event) ->
  $(this).removeClass('active-tile')
  