$ ->
  $(document).trigger('bg:review-sliders')

# $(document).on 'turbolinks:load', () ->
#   $(document).trigger('bg:review-sliders')

$(document).on 'mouseenter', 'form .review_criteria', (e) ->
  $('.current_review_criteria').removeClass('current_review_criteria')
  $(this).addClass('current_review_criteria')                      


$(document).on 'bg:review-sliders', () ->    

  slider_common_set_value = (slider, review_criteria, value, values) ->
    $('#' + $(slider).attr('data-field')).val(value)
    review_criteria.removeClass('review_criteria_value_' + v) for v in values
    review_criteria.addClass('review_criteria_value_' + value)

  update_average = () ->
    auto_update = $('#overall_slider').attr('auto-update') == "please"
    total = 0
    available = 0
    $('.review_criteria_value').each ->
      v = $(this).val() || 0
      v = parseInt(v,10)      
      if (0 < v)
        total += v
        available += 5
    rating = Math.round(total / available * 10)
    if rating.toString() == "NaN"
      rating = 0
    $('#overall_slider_average').removeClass('overall_criteria_value_' + v) for v in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    $('#overall_slider_average').addClass('overall_criteria_value_' + rating )
    if auto_update     
      $('#overall_slider').slider('value',rating)      
      slider_common_set_value($('#overall_slider')[0], $($('#overall_slider').parents('.review_criteria')[0]), rating, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
      display_rating_value = "N/A"
      if (0 != rating) then display_rating_value = rating + " / 10"
      $('#overall_slider').parents('.review_criteria').find('.review_value').html(display_rating_value)    

  slider_common = (event, ui, values) ->
    $('.review_criteria').removeClass('current_review_criteria')
    review_criteria = $(event.target).parents('.review_criteria')
    review_criteria.addClass('current_review_criteria')
    slider_common_set_value(event.target, review_criteria, ui.value, values)    
    review_criteria.find('.review_slider_labels').fadeIn()
      
  $('.review_comment_indicator').click ->
    $('.current_review_criteria').removeClass('current_review_criteria')
    $($(this).parents('.review_criteria')[0]).addClass('current_review_criteria')                      

  $('.review_comment_text').keyup ->
    text_length = $(this).val().length
    remaining = 140 - text_length
    $(this).parent().find('.length_counter').html(remaining)
    if (0 < text_length )
      $(this).parents('.review_criteria').addClass('review_with_comment')    
    else
      $(this).parents('.review_criteria').removeClass('review_with_comment')    
    return (0 < remaining)
      
  $('[data-behavior~=review_criteria_slider_five]').each ->
    initial_value = $('#' + $(this).attr('data-field')).val()
    $(this).slider 
      value: initial_value, 
      min: 0, 
      max: 5,
      step: 1,
      animate: 'fast',
      slide: (event, ui) ->
        slider_common(event, ui, [0, 1, 2, 3, 4, 5])
        if (0 != ui.value)  
          $(event.target).parents('.review_criteria').find('.review_value').hide()
        else
          $(event.target).parents('.review_criteria').find('.review_value').show()
        update_average()
      
  $('[data-behavior~=review_criteria_slider_ten]').each ->    
    initial_value = $('#' + $(this).attr('data-field')).val()    
    $(this).slider
      value: initial_value, 
      min: 0, 
      max: 10,
      step: 1,
      animate: 'fast',
      slide: (event, ui) ->
        slider_common(event, ui, [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
        display_rating_value = "N/A"
        if (0 != ui.value) then display_rating_value = ui.value + " / 10"
        $(event.target).parents('.review_criteria').find('.review_value').html(display_rating_value)
        $(this).attr('auto-update', '') if $(this).attr('auto-update') == "please"
      
  $('[data-behavior~=review_criteria_slider_middle]').each ->        
    initial_value = $('#' + $(this).attr('data-field')).val()
    $(this).slider
      value: initial_value, 
      min: 0, 
      max: 6,
      step: 1,
      animate: 'fast'
      slide: (event, ui) ->
        slider_common(event, ui, [0, 1, 2, 3, 4, 5, 6])
        if (0 != ui.value)  
          $(event.target).parents('.review_criteria').find('.review_value').hide()
        else
          $(event.target).parents('.review_criteria').find('.review_value').show()
        update_average()     
  
  
  