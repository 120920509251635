$ ->  

  # $('.hover-admin').tooltipster({
  #   position: 'top-left',
  #   interactive: true,
  #   offsetY: -10,
  #   arrow: false,
  #   delay: 0, speed: 133, interactiveTolerance: 1000,
  #   theme: '.tooltipster-sneaky',
  #   functionBefore: (origin, continueTooltip) ->
  #     origin.data('tooltipsterContent', $($(origin).attr('data-hover-content')).html())
  #     continueTooltip()
  # })

  # $('.hover-profile').tooltipster({
  #   position: 'bottom-left',
  #   interactive: true,
  # 
  #   delay: 0, speed: 133, 
  #   theme: '.tooltipster-noir',
  #   functionBefore: (origin, continueTooltip) ->
  #     $.ajax({          
  #       url: '/a/' + $(origin).attr('data-profile')
  #       success: (data) ->
  #         origin.data('tooltipsterContent', data)
  #         continueTooltip()
  #     })        
  # })
  # 
