
$(document).on 'click tap', '[data-action~=submit-form]', (e) ->
  $($(this).attr('data-target')).submit()
  e.preventDefault()

$(document).on 'keyup', '#profile_username', (event) ->  
  new_value = $.trim($(this).val())    
  new_value = 'username' unless !!new_value
  $('.profile_prefer_username').find('.btn').last().html(new_value)
  $('.profile_prefer_username').find('.btn').last().trigger('click') unless !!$('#profile_first_name').val() || !!$('#profile_last_name').val()

$(document).on 'keyup', '#profile_first_name, #profile_last_name', (event) ->
  new_value = $.trim($('#profile_first_name').val())
  last = $.trim($('#profile_last_name').val())
  new_value = new_value + ' ' + last[0] + '.' if !!last
  new_value = 'first name, last initial' unless !!new_value  
  $('.profile_prefer_username').find('.btn').first().html(new_value)

$(document).on 'shown.bs.tab', (e) ->
  $($(e.relatedTarget).attr('data-toggle-extra')).hide()
  
  $(e.target).siblings().each( () -> # !HACK    
    $($(this).attr('data-toggle-extra')).hide()
  )
  
  $($(e.target).attr('data-toggle-extra')).show()
  $('#profile_current_tab').val($(e.target).attr('href'))


  
$(document).on 'click', '#deactivate_confirm', () ->
  if !($(this).attr('checked') == 'checked') 
    $('#deactivate_account').attr('disabled', true)
    $('#deactivate_account').addClass('disabled')
  else
    $('#deactivate_account').attr('disabled', false)
    $('#deactivate_account').removeClass('disabled')

