  
$(document).on('click tap', '.editable-area-cancel', () -> 
  $(this).parents('.editable-area').find('.editable-area-edit').hide()  
  $(this).parents('.editable-area').find('.editable-area-view').fadeIn('fast')    
)
  
$(document).on('mouseenter', '.editable-details', () ->
  $(this).parents('.editable-area').find('.editable-area-view').addClass('hover')
  $(this).parents('.editable-area').find('.editable-area-toggle').show()
)

$(document).on('mouseleave', '.editable-details', () ->
  $(this).parents('.editable-area').find('.editable-area-view').removeClass('hover')
  $(this).parents('.editable-area').find('.editable-area-toggle').hide()
)
  
$(document).on('click tap', '.editable-area-toggle, .editable-area-view', () -> 
  $(this).parents('.editable-area').find('.editable-area-view').hide()  
  $(this).parents('.editable-area').find('.editable-area-edit').fadeIn('fast')    
  false
)

$(document).on('turbolinks:click', (event) ->
  if (event.target.getAttribute('href').charAt(0) == '#')
    return event.preventDefault()
)
  
# $(document).on('click tap', '[data-action~=scroll-to]', (e) ->
#   document.getElementById($(this).attr('target')).scrollIntoView(true);
#   e.preventDefault()
#   # false
# )
  
  
$(document).on('click tap', '[data-action~=sort-blush-reviews]', () ->
  $(this).parent().parent().find('li').removeClass('active')
  $(this).parent().parent().find('i').hide()
  $(this).find('i.' + $(this).attr('data-sort')).show()
  $(this).parent().addClass('active')
  url = $(this).attr('data-url') + '.js?by=' + $(this).attr('data-sort')
  if $(this).attr('data-filter')
    url = url + '&for=' + $(this).attr('data-filter')  
  $.ajax({            
    url: url
  })
  false
)

$(document).on('click tap', '[data-action~=new-blush-review], [data-action~=new-blush-swatch], [data-action~=new-blush-dupe], [data-action~=blush-ingredients]', () ->
  if (typeof gtag == 'function')
    gtag('event', $(this).attr('data-event-action'), { 
      'event_category': $(this).attr('data-event-category'), 
      'event_label': $(this).attr('data-event-label'), 
      # 'event_value': $(this).attr('data-event-value'),
      'transport_type': 'beacon', 
    })
  
  # Turbolinks.visit($(this).attr('data-url'))  
  window.location.href = $(this).attr('data-url')
  false
)

$(document).on('click tap', '[data-action~=jump-to-top]', () ->
  window.scrollTo(0,0);
  false
)

$(document).on('click tap', '.nav-search-icon', () ->
  $('#nav-search').focus()
  false
)

$(document).on('click tap', '.close-nav-search', () ->
  $('#search_results_nav').hide()
  false
)



  
$(document).on('click tap', '[data-action~=blush-review-comments]', () ->
  t = $(this)
  if !t.attr('data-pulled')
    t.attr('data-pulled', true)
    $.ajax({            
      url: $(this).attr('href') + '.js?comments=please&criteria=' + $(this).attr('data-criteria')
    })
  else
    if $('.popover').hasClass('in')
      t.popover('hide')
    else
      t.popover('show')            
  false    
)


# $(document).on('click tap', '.eyedropper', () ->
#   $('#colorpicker').colorpicker({
#       useAlpha: false
#     });
#   false
# )


$(document).on('click tap', '.eyedropper', () ->
  image = $('.review-top-image')
  if image.hasClass('eyedropper-please')
    if image.attr('data-src')
      image.attr('src', image.attr('data-src'))    
  else
    if image.attr('data-picker')
      image.attr('data-src', image.attr('src'))
      image.attr('src', image.attr('data-picker'))    
      
  image.toggleClass('eyedropper-please')  
)

$(document).on('click tap', '.eyedropper-please', (e) ->    
  i = $(this)[0]
  image_width = Math.floor(i.width)
  image_height = Math.floor(i.height)
    

  canvas = document.createElement('canvas')
  canvas.width = image_width
  canvas.height = image_height
  context = canvas.getContext("2d")  
  context.drawImage(i, 0, 0, image_width, image_height)
  
  image_offset = $(i).offset();
  canvas_x = Math.floor(e.pageX - image_offset.left)
  canvas_y = Math.floor(e.pageY - image_offset.top)  
  
  image_data = context.getImageData(canvas_x, canvas_y, 1, 1).data

  # console.log canvas_x + ',' + canvas_y + ' | ' + e.pageX + ',' + e.pageY + ' | ' + image_offset.left + ',' + image_offset.top + ' | ' + image_width + ',' + image_height + ' | '

  pixel_color = "rgba(" + image_data[0] + ", " + image_data[1] + ", " + image_data[2] + ", " + image_data[3] + ")"

  $('#blush_color').val(pixel_color)
  $('.swatch').css('backgroundColor', pixel_color)
  $('#blush_color').trigger('change')

  false
)





basic_have_update = (event, base_url, original_this) ->
  url = base_url
  blush_please = $(original_this).attr('data-blush') == 'please'
  recent_please = $(original_this).attr('data-recent') == 'please'
  if blush_please || recent_please
    url += '?'
    url += 'blush=please' if blush_please
    url += '&' if blush_please && recent_please
    url += 'recent=please' if recent_please
  t = $(original_this)
  $.ajax({
    type: event.data.verb,
    url: url,
    data: event.data.data
    success: ->
      t.parents('.btn-group').find('.btn-inverse').removeClass('btn-inverse').addClass('btn-default')
      t.removeClass('btn-default').addClass('btn-inverse')
  })    
      
blush_have_want_had = (event) ->
  url = '/b/' + $(this).attr('data-blush-id') + '/haves.js'
  basic_have_update(event, url, this)
      
$(document).on('click tap','[data-behavior~=have_it]', { verb:'POST' }, blush_have_want_had)
$(document).on('click tap','[data-behavior~=want_it]', { verb:'POST', data:{'have':{'want':true}} }, blush_have_want_had)
$(document).on('click tap','[data-behavior~=had_it]' , { verb:'POST', data:{'have':{'had' :true}} }, blush_have_want_had)
    
have_update = (event) -> 
  url = '/haves/' + $(this).attr('data-have-id') + '.js'
  basic_have_update(event, url, this)

$(document).on('click tap','[data-behavior~=have_remove]',{ verb:'DELETE' }, have_update)    
$(document).on('click tap','[data-behavior~=have_have]',{ verb:'PUT', data:{'have':{'want':false, 'had':false}} }, have_update)
$(document).on('click tap','[data-behavior~=have_want]',{ verb:'PUT', data:{'have':{'want':true,  'had':false}} }, have_update)  
$(document).on('click tap','[data-behavior~=have_had]' ,{ verb:'PUT', data:{'have':{'want':false, 'had':true }} }, have_update)    


have_remove_timeout = null
$(document).on 'mouseenter', '.btn-inverse[data-behavior^=have_]', () ->
  $t = $(this)
  have_remove_timeout = setTimeout () ->
    toolbar = $($t).closest('.btn-toolbar')
    toolbar.find('.btn-group-have').hide()
    toolbar.find('.btn-group-remove').show()
    
    # toolbar.find('.btn-group-have').fadeOut 100, () ->
    #   toolbar.find('.btn-group-remove').fadeIn 200    
  , 500
  
$(document).on 'mouseleave', '[data-behavior~=have_remove]', () ->
  toolbar = $(this).closest('.btn-toolbar')
  toolbar.find('.btn-group-remove').hide()
  toolbar.find('.btn-group-have').show()
  
  # toolbar.find('.btn-group-remove').stop().fadeOut 200, () ->
  #   toolbar.find('.btn-group-have').fadeIn 100 
  


  
  
$(document).on 'mouseleave', '.btn-inverse[data-behavior^=have_]', () ->
  clearTimeout(have_remove_timeout)
  
  
$(document).on('click tap', '.collection-toggle', () ->
  $(this).text = ''
)

$(document).bind('bg:updated', (e, data) ->
  $('.tooltip').hide()    
)

$(document).on('mouseenter', '[data-behavior~=tooltip-manual-content]', () -> 
  html = $(this).parents('.thumbnail').find('.tooltip_content').first().html()
    
  $(this).tooltip('hide').
    attr('data-original-title', html).
    tooltip('fixTitle').
    tooltip('show')
  $('.actionable').not(this).tooltip('hide')
  $(this).tooltip('show')
)

$ -> 
  $(document).trigger('bg:blush-typeaheads')  
  
mapped_brands = {}
mapped_products = {}
mapped_shades = {}    
mapped_retail_collections = {}

$(document).on 'change', '#blush_category', (e) -> 
  $('#blush_category_selection').val('1')
  $('#new_blush').submit()

$(document).on('bg:blush-typeaheads', () ->    

  # $('#blush_category').change ->
  #   $('#blush_category_selection').val('1')
  #   $('#new_blush').submit()
    
  $('.brand_search').typeahead(
      source: (query, process) ->
        $.get('/brands.json', { q: query }, (data) ->
          labels = []
          $.each(data.data, (i, b) ->
            query_label = b.attributes.name
            mapped_brands[query_label] = b
            labels.push(query_label)
          )
          process(labels);
        ,'json')
      updater: (query_label) ->
        b = mapped_brands[query_label]
        $($('.brand_search').attr('data-field')).val(b.id)        
        $('.brand_search_image').attr('src', b.attributes['image-url-small'])
        $('.brand_search_image').show()
        return query_label
      # highlighter: (query_label) ->
      #   b = mapped_brands[query_label]
      #   query = this.query.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&')
      # 
      #   highlighted_label = query_label.replace(new RegExp('(' + query + ')', 'ig'), ($1, match) -> 
      #     return '<strong>' + match + '</strong>'
      #   )
      # 
      #   view_label = highlighted_label + '<img class="lookup_image_small" src="' + b.image_url_small + '">'
      #   return view_label
  )    
  
  $('.retail_collection_search').typeahead(
      source: (query, process) ->
        $.get('/retail_collections.json', { q: query, brand: $('.brand_search').val(), brand_id: $('#blush_brand_id').val() }, (data) ->
          labels = []          
          $.each(data.data, (i, b) ->
            query_label = b.attributes.name
            mapped_retail_collections[query_label] = b
            labels.push(query_label)
          )
          process(labels);
        ,'json')
      updater: (query_label) ->
        b = mapped_retail_collections[query_label]
        $($('.retail_collection_search').attr('data-field')).val(b.id)        
        $('.retail_collection_search_image').attr('src', b.attributes['image-url-small'])
        $('.retail_collection_search_image').show()        
        return query_label
      # highlighter: (query_label) ->
      #   b = mapped_retail_collections[query_label]
      #   query = this.query.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&')
      # 
      #   highlighted_label = query_label.replace(new RegExp('(' + query + ')', 'ig'), ($1, match) -> 
      #     return '<strong>' + match + '</strong>'
      #   )
      # 
      #   view_label = highlighted_label + '<img class="lookup_image_small" src="' + b.image_url_small + '">'
      #   return view_label
  )     
  
    
  $('.product_search').typeahead(
      source: (query, process) ->
        $.get('/p.json', { q: query, brand: $('.brand_search').val(), brand_id: $('#blush_brand_id').val() }, (data) ->
          labels = []          
          $.each(data.data, (i, p) ->
            query_label = p.attributes.name
            mapped_products[query_label] = p
            labels.push(query_label)
          )

          process(labels);
        ,'json')        
      updater: (query_label) ->
        p = mapped_products[query_label]
        $($('.product_search').attr('data-field')).val(p.id)
        # $('.product_search_image').attr('src', p.image_url_small)
        # $('.product_search_image').show()        
        return query_label
      # highlighter: (query_label) ->
      #   p = mapped_products[query_label]
      #   query = this.query.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&')
      # 
      #   highlighted_label = query_label.replace(new RegExp('(' + query + ')', 'ig'), ($1, match) -> 
      #     return '<strong>' + match + '</strong>'
      #   )
      # 
      #   view_label = query_label
      #   # view_label = highlighted_label + '<img class="lookup_image_small" src="' + p.image_url_small + '">'
      #   return view_label      
  )
    
  $('.shade_search').typeahead(
      source: (query, process) ->
        $.get('/shades.json', { q: query, brand: $('.brand_search').val(), brand_id: $('#blush_brand_id').val()  }, (data) ->
          labels = []          
          $.each(data.data, (i, s) ->
            query_label = s.attributes.name
            mapped_shades[query_label] = s
            labels.push(query_label)
          )

          process(labels);
        ,'json')        
      updater: (query_label) ->
        s = mapped_shades[query_label]
        $($('.shade_search').attr('data-field')).val(s.id)
        # $('.shade_search_image').attr('src', s.image_url_small)
        # $('.shade_search_image').show()        
        return query_label
      # highlighter: (query_label) ->
      #   s = mapped_products[query_label]
      #   query = this.query.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&')
      # 
      #   highlighted_label = query_label.replace(new RegExp('(' + query + ')', 'ig'), ($1, match) -> 
      #     return '<strong>' + match + '</strong>'
      #   )
      # 
      #   view_label = query_label
      #   # view_label = highlighted_label + '<img class="lookup_image_small" src="' + p.image_url_small + '">'
      #   return view_label       
  )
  
)
    

  
  
$(document).on('click tap', '.blush-interactive-frame', () ->        
  return if (0 == $('#default-kit').length)
    
  clone_frame = $(this).clone()    
  clone = clone_frame.find('.blush-interactive')
            
  # update data
  url = '/kits/' + $('#default-kit').attr('data-kit-id') + '/inclusions.js'
  t = $(this)
  $.ajax({
    type: 'POST',
    url: url,
    data: { blush_id: $(clone).attr('data-blush-id') },
    # success: ->
    #   t.parents('.actions').find('.btn').removeClass('btn-inverse')        
    #   t.addClass('btn-inverse')      
    # beforeSend: (xhr, settings) ->
    #   xhr.setRequestHeader('accept', '*/*;q=0.5, ' + settings.accepts.script)

  })
            
  # add to kit effect
  $(this).parents('.tab-content').css('overflow','visible')    
    
  position = $(this).position()
  offset = $(this).position()    
    
  # $('#debug-position-left').html(position.left)
  # $('#debug-position-top').html(position.top)
  # $('#debug-offset-left').html(offset.left)
  # $('#debug-offset-top').html(offset.top)
    
  target_position = $('#default-kit').offset()
    
  initial_y = position.top #- $(window).scrollTop()
    
  target_x = target_position.left + 70
  target_y = target_position.top
    
  # $('#debug-target-x').html(target_x)
  # $('#debug-target-y').html(target_y)
        
  bezier_params = { start: { x: position.left, y: initial_y , angle: -90, length: 0.4 }, end: { x: target_x, y: target_y, angle: 0, length: 0.6 } }

  clone_frame.addClass('blush-interactive-add-to-kit-frame')
  clone.addClass('blush-interactive-add-to-kit')
    
  clone.appendTo($(this).parent())
    
  duration = 100 + Math.round(Math.abs(target_y - initial_y) * 0.8)
    
  $(clone).animate( { path: new $.path.bezier(bezier_params) }, duration, 'linear', () -> 
    $(clone_frame).remove()    
    $(clone).remove()
  ) 
    		    
)
  
  
  
  
  
  
  
  
