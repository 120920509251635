
$(document).on('click tap', '[data-action~=kit-modal]', () ->    
  $('body').modalmanager('loading') 
  url = '/kits/' + $(this).attr('data-kit-id') + ".js?modal=please"  
  $('#kit-modal').load(url, '', () ->
    $('body').modalmanager('loading')        
    $('#kit-modal').modal()
  )
  false
)
  
$(document).on('click tap', '#kit-selection tr', () ->
  inclusion_id = $(this).attr('data-inclusion-id')
  if inclusion_id
    url = '/kits/' + $('#kit-selection').attr('data-kit-id') + '/inclusions/' + inclusion_id + ".js"
    t = $(this)
    $.ajax({
      type: 'PUT',
      url: url,
      data: { inclusion: { selected: true }, modal: 'please' }
    })
  false        
)
    
