$ ->

  $('#favorites').imagesLoaded ()->
    $('#favorites').masonry { 
      isFitWidth: false,
      isAnimated: true,
      itemSelector: '.favorite',
      
      gutterWidth: ( containerWidth ) ->
        # window_width = $(window).width()
        # gutter = (window_width < 1200) ? 20 : 30
        gutter = 20
        gutter
        
      columnWidth: ( containerWidth ) ->
        # window_width = $(window).width()
        # gutter = (window_width < 1200) ? 20 : 30
        gutter = 20        
        (containerWidth - (gutter * 11)) / 12
    } 
