$(document).on 'tap click', '.input-group-addon', () ->
  $(this).closest('.input-group').find('input').focus()

# $ ->
#   $('.select2').select2()

$(document).on 'change', '.editable-details input', () ->
  # actions = $(this).parents('.editable-details').find('.editable-actions')
  # actions.addClass('active')
  # actions.fadeIn('fast')

$(document).on 'change keypress', '.form-blush input, .form-blush textarea', () ->
  $('body').addClass('header-buttons-visible')
  actions = $(this).parents('.form-blush').find('.header-buttons')
  actions.fadeIn('fast')

$(document).on 'tap click', '.editable-actions .btn', () ->
  $(this).parents('.editable-actions').removeClass('active')
  $(this).parents('.editable-actions').fadeOut('fast')

$(document).on 'mouseenter', '.editable-details, .form-viewing', () ->
  $(this).find('.editable-actions.active').fadeIn('fast')
  # $(this).addClass('hover')
  # $(this).find('.inplace-empty').fadeIn('fast')
  $(this).find('.inplace-empty').fadeIn('fast')
# )
#
$(document).on 'mouseleave', '.editable-details, .form-viewing', () ->
  $(this).find('.editable-actions').fadeOut('fast')
  # $(this).find('.inplace-empty').slideUp('fast')
  # $(this).removeClass('hover')
#   # $(this).find('.inplace-empty').fadeOut()
#   # $(this).find('.inplace-empty').hide()
# )

$(document).on 'tap click', '.bg-toggle a', (e) ->
  bg_toggle = $(this).closest('.bg-toggle')
  new_value = $(this).attr('data-value')
  bg_toggle.find('input').val(new_value)
  bg_toggle.find('input').trigger('change')

  bg_toggle.find('a').each( () ->
    if new_value != $(this).attr('data-value')
      $(this).closest('.btn-group').addClass('active').show()
    else
      $(this).closest('.btn-group').removeClass('active').hide()
  )

  e.preventDefault()
  false
