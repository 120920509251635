
# $(document).on('click tap', '[data-action~=flag-modal]', () ->    
#   $('body').modalmanager('loading') 
#   url = '/flags/' + $(this).attr('data-flag-id') + ".js?modal=please"  
#   $('#flag-modal').load(url, '', () ->
#     $('body').modalmanager('loading')        
#     $('#flag-modal').modal()
#   )
#   false
# )

$(document).on('click tap', '[data-action~=flag]', (e) ->    
  flag_url = $(this).attr('data-flag-url')
  flag_type = $(this).attr('data-flag-method')
  $.ajax({
    type: flag_type,
    url: flag_url
  })      
  e.preventDefault()
  false
)

$(document).on 'click tap', '.flag-details', (e) ->
  $(this).closest('#flagged').find('.flag-notes').toggle()
  $('#flag_details').focus()
  e.preventDefault()