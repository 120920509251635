$(document).on 'click tap', 'form .primary-toggle', (event) ->
  # !TODO for multiples per page: need to start from $(this), not all toggles
  # $(this).closest('fieldset').closest('fieldset').find('.primary-toggle').prev('input[type=hidden]').val('0')  
  $('.primary-toggle').closest('fieldset').closest('fieldset').find('.primary-toggle').each( ()->
    $(this).find('i,svg').addClass('text-muted')
    $(this).find('i,svg').removeClass('text-star')
    $(this).prev('input[type=hidden]').val('0')
  )
  $(this).prev('input[type=hidden]').val('1')
  $(this).find('i,svg').addClass('text-star')        
  $(this).find('i,svg').removeClass('text-muted')

  event.preventDefault()
  
$(document).on 'click tap', 'form .screenshot-toggle,form .favicon-toggle', (event) ->  

  if $(this).prev('input[type=hidden]').val() == 'true'
    $(this).prev('input[type=hidden]').val('false')
    $(this).find('i,svg').addClass('text-muted')
    $(this).find('i,svg').removeClass('text-primary')
  else
    $(this).prev('input[type=hidden]').val('true')
    $(this).find('i,svg').addClass('text-primary')
    $(this).find('i,svg').removeClass('text-muted')
  event.preventDefault()  
  
$ ->
  
  $('#slider').nivoSlider({
    effect: 'fade',
    animSpeed: 70,
    controlNavThumbs: true,
    directionNav: false,
    manualAdvance: true
  })
  
