
# google analytics
# $(document).on 'page:change', (e) ->
#   ga('send', 'pageview', window.location.pathname)


$(document).on 'click tap', '.btn-group-radio-form .btn, .btn-group-radio-form-deselect', (e) ->
  deselect = $(this).hasClass('btn-group-radio-form-deselect')
  btn_group_radio_form = if deselect then $(this).parent().children('.btn-group-radio-form') else $(this).parent('.btn-group-radio-form')
  
  # btn_group_radio_form.children('.btn').removeClass('btn-inverse').addClass('btn-default')
  btn_group_radio_form.children('.btn').each () ->
    # $(this).removeClass('btn-inverse').addClass('btn-default')
    active_class = $(this).attr('data-active-class')
    active_class = 'btn-inverse' unless !!active_class
      
    default_class = $(this).attr('data-default-class')
    default_class = 'btn-default' unless !!default_class
      
    $(this).removeClass(active_class).addClass(default_class)    
    
  btn_group_radio_form.children('.btn').removeClass('active') if deselect
  # $(this).addClass('btn-inverse').removeClass('btn-default') unless deselect
  unless deselect
    active_class = $(this).attr('data-active-class')
    active_class = 'btn-inverse' unless !!active_class
      
    default_class = $(this).attr('data-default-class')
    default_class = 'btn-default' unless !!default_class
      
    $(this).addClass(active_class).removeClass(default_class)
  
  $(btn_group_radio_form.attr('data-field')).val($(this).attr('data-value'))
  e.preventDefault()
  
$(document).on 'click tap', '.bootstrap-dropdown li a', (e) ->
  dropdown = $(this).closest('.bootstrap-dropdown')
  dropdown.find('a.active').removeClass('active')
  $(this).addClass('active')
  dropdown.find('input').val($(this).attr('data-value'))
  the_label = $(this).attr('data-label')
  the_label = $(this).attr('data-value') unless !!the_label
  dropdown.find('.dropdown-toggle strong').html(the_label)  
  dropdown.find('input').trigger('change')
  e.preventDefault()  
  

$(document).on 'click', '.bootstrap_toggle .btn', (event) ->
  $(this).parents('.bootstrap_toggle').find('.btn').each( () ->
    $(this).removeClass($(this).attr('data-active-class'))
  )
  $(this).addClass($(this).attr('data-active-class'))
  $($(this).parents('.bootstrap_toggle').attr('data-field')).val($(this).attr('data-value'))


$(window).resize () ->
  $('.tooltip').hide()

$(document).on 'turbolinks:load', () ->
  $('.focus-me').focus()

$ ->
  $('.focus-me').focus()
  $('.carousel').carousel(interval: 12000)
  $('.date-picker').datepicker {
    format: 'mm-dd-yyyy'
  }
