$(document).on 'click tap', 'form .remove-fields', (event) ->
  $(this).prev('input[type=hidden]').val('1')
  $(this).closest('fieldset').hide()
  event.preventDefault()

$(document).on 'click tap', 'form .add-fields', (event) ->
  time = new Date().getTime()
  regexp = new RegExp($(this).data('id'), 'g')
  $(this).before($(this).data('fields').replace(regexp, time))
  event.preventDefault()

$(document).on 'click tap', '.show-hide-please', (event) ->
  $($(this).attr('data-hide')).hide()
  $($(this).attr('data-show')).show()
  event.preventDefault()
