
$(document).on('keyup', '.post-subject-inplace-list', (event) ->
  if ('' == $.trim($(this).val()))
    $('#new-post').fadeOut()
  else
    $('#new-post').fadeIn()
)

$(document).on('click tap', '[data-behavior~=focus]', () ->      
  $($(this).attr('data-target'))[0].scrollIntoView()
  $($(this).attr('data-target')).focus()
  return false
)

$(document).on('click tap', '[data-action~=toggle]', () ->      
  $($(this).attr('data-target')).toggle()
  return false
)
