// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
Rails.start()
// require("turbolinks").start()
// Turbolinks.start()
require("@rails/activestorage").start()
require("channels")

import $ from 'jquery'; window.jQuery = $; window.$ = $;

require("jquery-ui")
import 'jquery-ui/ui/effect.js';
import 'jquery-ui/ui/effects/effect-blind.js';
import 'jquery-ui/ui/effects/effect-bounce.js';
import 'jquery-ui/ui/effects/effect-clip.js';
import 'jquery-ui/ui/effects/effect-drop.js';
import 'jquery-ui/ui/effects/effect-explode.js';
import 'jquery-ui/ui/effects/effect-fade.js';
import 'jquery-ui/ui/effects/effect-fold.js';
import 'jquery-ui/ui/effects/effect-highlight.js';
import 'jquery-ui/ui/effects/effect-puff.js';
import 'jquery-ui/ui/effects/effect-pulsate.js';
import 'jquery-ui/ui/effects/effect-scale.js';
import 'jquery-ui/ui/effects/effect-shake.js';
import 'jquery-ui/ui/widgets/slider.js';
import 'jquery-ui/ui/widgets/tooltip.js';



require("bootstrap-datepicker")

import '../src/js/vendor/jquery-migrate-1.2.1.min.js';

import '../src/js/vendor/masonry/jquery.masonry.js';
import '../src/js/vendor/twitter/bootstrap/bootstrap-typeahead.js';


require("select2")
// require("jquery.typewatch")

import 'jquery.typewatch/jquery.typewatch.js';
import 'nivo-slider/jquery.nivo.slider.js';
import '@fortawesome/fontawesome-free/js/all.js';



import 'bootstrap/dist/js/bootstrap';

import '../src/js/blushes.js.coffee';
import '../src/js/blushgarden.js.coffee';
import '../src/js/bootstrap.js.coffee';
import '../src/js/categories.js.coffee';
import '../src/js/collections.js.coffee';
import '../src/js/compatibility.js.coffee';
import '../src/js/discuss.js.coffee';
import '../src/js/favorites.js.coffee';
import '../src/js/flags.js.coffee';
import '../src/js/forms.js.coffee';
import '../src/js/help.js.coffee';
import '../src/js/hovercards.js.coffee';
import '../src/js/images.js.coffee';
import '../src/js/kits.js.coffee';
import '../src/js/networks.js.coffee';
import '../src/js/profiles.js.coffee';
import '../src/js/reviews.js.coffee';
