  
$(document).on 'assert:additions', () ->
  $('.tooltip').hide()
  $('#collection').hide()
  $('#additions').fadeIn('fast')

$(document).on 'assert:collection', () ->
  $('.tooltip').hide()        
  $('#additions').hide()
  $('#collection').fadeIn('fast')
  
$(document).on 'assert:collection:render', () ->
  $('.query[data-behavior~=auto-submit], .search-collection-query').typeWatch {
    callback: (value) ->
      Rails.fire($(this).closest('form')[0], 'submit')
    wait: 400,
    highlight: true,
    captureLength: 0
  }
  
$ ->
  $(document).trigger('assert:collection:render')

$(document).on 'turbolinks:load', () ->
  $(document).trigger('assert:collection:render')

$(document).on 'click tap', '.request-additions', (event) ->
  $(document).trigger('assert:additions')
  event.preventDefault()


$(document).on 'click tap', '[data-behavior~=update-display]', (event) ->
  $.ajax({
    type: 'PUT',
    url: $(this).attr('href') + '.js',
    data: {'collection':{'display':$(this).attr('data-display')}}
  })
  event.preventDefault()

$(document).on 'click tap', '[data-behavior~=update-mode]', (event) ->
  $.ajax({
    type: 'PUT',
    url: $(this).attr('href') + '.js',
    data: {'collection':{'mode':$(this).attr('data-mode')}}
  })
  event.preventDefault()


$(document).on 'show.bs.tab', '#collection-add-options a[data-toggle="pill"]', (e) ->   
  $($(e.target).attr('data-hide-extra')).fadeOut('fast')
  $($(e.relatedTarget).attr('data-hide-extra')).fadeIn('fast')
  $('.tooltip').hide()

$(document).on 'shown.bs.tab', '#collection-add-options a[data-toggle="pill"]', (e) ->   
  $($(e.relatedTarget).attr('data-suppress-extra')).animate({height: 'toggle', opacity: 'toggle'}, 'fast' )      
  $($(e.target).attr('data-suppress-extra')).animate({height: 'toggle', opacity: 'toggle'}, 'fast' )  



$(document).on 'click tap', '.request-add', (e) ->
  $('#collection-add-options a[href="#add"]').tab('show')
  e.preventDefault()
  
$(document).on 'click tap', '.request-browse', (e) ->
  $('#collection-add-options a[href="#browse"]').tab('show')
  e.preventDefault()

$(document).on 'click tap', '.request-popular', (e) ->
  $('#collection-add-options a[href="#popular"]').tab('show')
  e.preventDefault()
  
$(document).on 'click tap', '.request-search', (e) ->  
  $('#collection-add-options a[href="#search"]').tab('show')
  e.preventDefault()

$(document).on 'click tap', '.request-submit', (event) ->
  $('#blush_have').val('')
  $('#blush_have').val($(this).attr('data-have'))      
  $('#new_blush').trigger('submit')
  $('#collection-add-options a[href="#browse"]').tab('show')
  $('#blush_have').val('')

$(document).bind('bg:done_adding', () ->
  $('#collection-add-options a[href="#popular"]').tab('show')
)

$(document).on 'bg:search_results', () ->
  $('#collection-add-options a[href="#search"]').tab('show')

$(document).on 'bg:no_search_results', () ->
  $('#collection-add-options a[href="#search"]').tab('show')
  
$(document).on 'bg:add_blush', () ->
  $('.tooltip').hide()
  $('#collection-add-options a[href="#add"]').tab('show')
 
$(document).on 'click tap', '#find_search', (event) ->
  $(document).trigger('bg:search_results')
  
$(document).on 'click tap', '#find_browse', (event) ->   
  $(document).trigger('bg:no_search_results')
  $('#search_results, #add_blush').hide()    

